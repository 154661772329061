import React, { FC, ReactNode, useState, useEffect } from 'react';
import ImageWebp from "../layout/ImageWebp/ImageWebp";
import { avatarImg, coinImg, pixelIconImg } from "../../assets/images";
import { IFren } from "../../models/IFren";
import styles from "./FrenItem.module.scss";

interface FrenItemProps {
  item: IFren,
  children: ReactNode,
  showPixels?: boolean
  className?: string
}

const FrenItem: FC<FrenItemProps> = ({ item, showPixels, children, className }) => {
  const [avatarSrc, setAvatarSrc] = useState<string>(avatarImg);

  useEffect(() => {
    const checkUserAvatar = async () => {
      const userAvatar = `https://thetonmap.xyz/images/user_avatars/${item.user_id}.jpg`;
      try {
        const response = await fetch(userAvatar);
        if (response.ok) {
          setAvatarSrc(userAvatar);
        } else {
          setAvatarSrc(avatarImg); 
        }
      } catch (error) {
        setAvatarSrc(avatarImg); 
      }
    };

    checkUserAvatar();
  }, [item.user_id]);

  return (
    <div className={`${styles.frenItem} ${className || ""}`} key={item.user_id}>
      <div className={styles.frenItem__main}>
        <ImageWebp
          src={avatarSrc}
          srcSet={`${avatarSrc.replace('.jpg', '.webp')} 1x, ${avatarSrc} 2x`} // Поддержка 2x JPEG
          alt="avatar"
          className={styles.frenItem__coinImg} />
        <div className={styles.frenItem__info}>
          <h4 className={styles.frenItem__nameTxt}>{item.full_name}</h4>
          <div className={styles.frenItem__coins}>
            <ImageWebp
              src={showPixels ? pixelIconImg : coinImg}
              srcSet={showPixels ? pixelIconImg : coinImg} // Если WebP нет, используйте JPEG
              alt="coin"
              className={styles.frenItem__coinsImg}
            />
            <p className={styles.frenItem__coinsTxt}>{showPixels ? item.pixels : item.coins}</p>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
}

export default FrenItem;
