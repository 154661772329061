import React from 'react';

import styles from "./HomeEnergy.module.scss"
import {useAppSelector} from "../../../hooks/redux";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {energyIconImg, energyIconWebpImg} from "../../../assets/images";
import ProgressBar from "../../layout/ProgressBar/ProgressBar";

function HomeEnergy() {
    const energy = useAppSelector(state => state.profile.user.energy)
    const fullEnergy = useAppSelector(state => state.profile.user.fullEnergy)

    return (
        <div className={styles.homeEnergy}>
            <div className={styles.homeEnergy__info}>
                <ImageWebp
                    src={energyIconImg}
                    srcSet={energyIconWebpImg}
                    alt={"energy"}
                    className={styles.homeEnergy__icon}
                />
                <p className={styles.homeEnergy__txt}>{energy}/{fullEnergy}</p>
            </div>
            <ProgressBar value={energy} fullValue={fullEnergy}/>
        </div>
    );
}

export default HomeEnergy;