import React from 'react';
import {arrowIconImg, coinImg, coinWebpImg, diamondImg, diamondWebpImg} from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";

import styles from "./HomeInfo.module.scss"
import {useAppSelector} from "../../../hooks/redux";
import { useTranslation } from 'react-i18next';

function HomeInfo() {
    const { t } = useTranslation();
    const city = useAppSelector(state => state.profile.user.city)
    const rank = useAppSelector(state => state.profile.user.rank)
    const swipeIncome = useAppSelector(state => state.profile.user.swipeIncome)
    const passiveIncome = useAppSelector(state => state.profile.user.passiveIncome)

    return (
        <>
            <div className={styles.homeInfo}>
                {
                    city
                        ? <div className={styles.homeInfo__cityBlock}>
                            <div className={styles.homeInfo__cityName}>
                                <img src={city.avatar_url} alt="avatar" className={styles.homeInfo__avatarImg}/>
                                <h3 className={styles.homeInfo__cityNameTxt}>{city.name}</h3>
                            </div>
                            <div className={styles.homeInfo__rank}>
                                <ImageWebp
                                    src={diamondImg}
                                    srcSet={diamondWebpImg}
                                    alt="diamond"
                                    className={styles.homeInfo__rankImg}
                                />
                                <p className={styles.homeInfo__rankTxt}>{rank}</p>
                            </div>
                        </div>
                        : <div className={styles.homeInfo__citySelect}>
                            <div className={styles.homeInfo__flex}></div>
                            <h6 className={styles.homeInfo__citySelectTxt}>{t('messages.joinEmpireSoon')}</h6>
                            <div className={styles.homeInfo__citySelectArrow}>
                                <ImageWebp
                                    src={arrowIconImg}
                                    srcSet={arrowIconImg}
                                    alt={"icon"}
                                    className={styles.homeInfo__citySelectArrowIcon}
                                />
                            </div>
                        </div>
                }


                <div className={styles.homeInfo__miningBlock}>
                    <div className={styles.homeInfo__miningBtn}>
                        <ImageWebp
                            src={coinImg}
                            srcSet={coinWebpImg}
                            alt={'coin'}
                            className={styles.homeInfo__miningCoinImg}
                        />
                            <span className={styles.homeInfo__miningBtnTxt}>{t('messages.miningIncomeSwipe', { swipeIncome })}</span>

                    </div>
                    <div className={styles.homeInfo__miningBtn}>
                        <ImageWebp
                            src={coinImg}
                            srcSet={coinWebpImg}
                            alt={"coin"}
                            className={styles.homeInfo__miningCoinImg}
                        />
                            <span className={styles.homeInfo__miningBtnTxt}>{t('messages.miningIncomeHour', { passiveIncome })}</span>

                    </div>
                </div>
            </div>
        </>
    );
}

export default HomeInfo;

