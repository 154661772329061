type GetClb = (error: (string | null), result?: string) => void
type RemoveClb = (error: (string | null), result?: boolean) => void
type GetManyClb = (error: (string | null), result?: CloudStorageItems) => void

interface CloudStorageItems {
    [p: string]: string
}

export function getLSItem(key: string, clb: GetClb): void {
    if (window.Telegram?.WebApp.CloudStorage) window.Telegram.WebApp.CloudStorage.getItem(key, clb)
}

export function getLSItems(keys: string[], clb: GetManyClb): void {
    if (window.Telegram?.WebApp.CloudStorage) window.Telegram.WebApp.CloudStorage.getItems(keys, clb)
}

export function removeLSItem(key: string, clb: RemoveClb): void {
    if (window.Telegram?.WebApp.CloudStorage) window.Telegram.WebApp.CloudStorage.removeItem(key, clb)
}


export const setLSItem = <T>(key: string, data: T) => {
    if (window.Telegram?.WebApp.CloudStorage) {
        window.Telegram.WebApp.CloudStorage.setItem(key, typeof data === "string" ? data : JSON.stringify(data))    }
}
