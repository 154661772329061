import React, {FC} from 'react';

import styles from "./ProgressBar.module.scss"

interface ProgressBarProps {
    value: number,
    fullValue: number
}

const ProgressBar:FC<ProgressBarProps> = ({value,fullValue}) => {

    let energyPercent = value * 100 / fullValue
    if(energyPercent > 100) energyPercent = 100
    return (
        <div className={styles.progressbar}>
            <div
                style={{width: `${energyPercent}%`}}
                className={styles.progressbar__inner}
            >
                <div className={styles.progressbar__gradient}></div>
            </div>
        </div>
    );
}

export default ProgressBar;