import React, {FC} from 'react';
import TransitionProvider, {TransitionStyleTypes} from "../../providers/TransitionProvider";

import styles from "./Loader.module.scss"
import ImageWebp from "../layout/ImageWebp/ImageWebp";
import {rocketLoaderImg, rocketLoaderWebpImg} from "../../assets/images";
import {useAppSelector} from "../../hooks/redux";
import { useTranslation } from 'react-i18next';


interface LoaderProps {
    imagesLoading: boolean
}

const Loader: FC<LoaderProps> = ({imagesLoading}) => {
    const getLoading = useAppSelector(state => state.profile.getUserLoading)
    const { t } = useTranslation();

    const stopMouseEvent:  React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }

    const stopTouchEvent:   React.TouchEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation()
        e.preventDefault()
    }

    return (
        <TransitionProvider
            type={TransitionStyleTypes.opacityLeave}
            inProp={getLoading || imagesLoading}
            className={styles.loader}
            onMouseDown={stopMouseEvent}
            onMouseMove={stopMouseEvent}
            onTouchStart={stopTouchEvent}
            onTouchMove={stopTouchEvent}
        >
            <div className={styles.loader__circle}>
                <div className={styles.loader__circleInner}>
                    <ImageWebp
                        src={rocketLoaderImg}
                        srcSet={rocketLoaderWebpImg}
                        alt="rocket"
                        className={styles.loader__rocketImg}
                    />
                </div>
                <p className={styles.loader__text}>{t('loader.starting')}</p>
            </div>
        </TransitionProvider>
    );
}

export default Loader;