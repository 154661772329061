import React, {FC} from 'react';
import CustomModal from "../../layout/CustomModal/CustomModal";

import styles from "./ErrorFormModal.module.scss"


const ErrorFormModal: FC = () => {


    return (
        <CustomModal
            open={true}
            onClose={() => {}}
            hideOverlay={true}
        >
            <div className={styles.errorFormModal}>
                <div className={styles.errorFormModal__textblock}>
                    <h3 className={styles.errorFormModal__txt}>We are making our app better. Back later.</h3>
                </div>
            </div>

        </CustomModal>
    );
}

export default ErrorFormModal;