import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"
import {AppDispatch, RootState} from "../store";

import {fetchRequest, updateTaskStatusUrl} from "../tools/fetchTools";
import {Tasks} from "../../models/ITask";
import { UpdateTasksRequest} from "../../models/api/tasks/getTasks";
import {updateUserCoins} from "./profileSlice";



export interface TasksState {
    tasks: Tasks,
    updateLoading: boolean
}


const initialState: TasksState = {
    tasks: {},
    updateLoading: false
}

export const updateTaskStatus = createAsyncThunk<Tasks, Omit<UpdateTasksRequest, "user_id">, { rejectValue: string }>(
    'tasks/updateTaskStatus',
    async (data, { rejectWithValue, getState }) => {
        const state = getState() as RootState;
        if (!state.profile.user.userId) return rejectWithValue("Error");

        const reqData: UpdateTasksRequest = {
            user_id: state.profile.user.userId.toString(),
            ...data
        };

        try {
            await fetchRequest<void>(updateTaskStatusUrl, "POST", reqData);

            const updatedTasks = { ...state.tasks.tasks };
            const updatingTask = Object.keys(updatedTasks)
                .flatMap(key => updatedTasks[key].map(item => ({
                    ...item,
                    category: key
                })))
                .find(item => item.id === data.task_id);

            if (updatingTask) {
                // Проверяем наличие form_data перед обновлением статуса
                if (data.task_id !== 0) {//ads task
                    const updatingList = [...updatedTasks[updatingTask?.category]];
                    const updatingItemIndex = updatingList.findIndex(item => item.id === updatingTask.id);
                    if (updatingItemIndex === -1) return rejectWithValue("Error");

                    updatingList[updatingItemIndex] = {
                        ...updatingTask,
                        completed: true
                    };
                    updatedTasks[updatingTask?.category] = updatingList;
                }
            } else {
                return rejectWithValue("Error");
            }

            return updatedTasks;
        } catch (error) {
            return rejectWithValue("Error");
        }
    }
);



export const updateTaskStatusWithTimeOut = (data: Omit<UpdateTasksRequest, "user_id">, coins: number) => async (dispatch: AppDispatch) => {
    try {
        const resultAction = await dispatch(updateTaskStatus(data)).unwrap();
        if (data.task_id === 0) { //ads task
            dispatch(updateUserCoins(coins));
            dispatch(stopUpdateLoading());
        } else if (!data.form_data) {
            setTimeout(() => {
                dispatch(updateUserCoins(coins));
                dispatch(stopUpdateLoading());
            }, 5000);
        } else {
            dispatch(stopUpdateLoading());
        }
    } catch (err) {
        dispatch(stopUpdateLoading());
    }
}



export const tasksSlice = createSlice({
    name: 'tasksSlice',
    initialState,
    reducers: {
        stopUpdateLoading(state) {
            state.updateLoading = false
        },

        setTasks(state, {payload}) {
            state.tasks = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTaskStatus.pending, (state) => {
                state.updateLoading = true
            })
            .addCase(updateTaskStatus.fulfilled, (state, {payload}) => {
                state.tasks = payload
            })
            .addCase(updateTaskStatus.rejected, (state) => {
                state.updateLoading = false
            })
    }
})

export const {
    stopUpdateLoading,
    setTasks
} = tasksSlice.actions;


export default tasksSlice.reducer;
