import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from "./FrensInviteBtn.module.scss";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import { inviteIconImg, inviteIconWebpImg } from "../../../assets/images";
import MainBtn from "../../layout/MainBtn/MainBtn";
import { useAppSelector } from "../../../hooks/redux";

function FrensInviteBtn() {
    const { t } = useTranslation();
    const referral_link = useAppSelector(state => state.profile.user.referral_id);
    const BOT_URL = `https://t.me/thetonmapbot?start=tothemoon_${referral_link}`;

    const SHARE_TEXT = t('share_text');

    const onClick = () => {
        const encodedShareText = encodeURIComponent(SHARE_TEXT.trim());
        window.open(`https://t.me/share/url?url=${BOT_URL}&text=${encodedShareText}`, '_blank');
    };

    return (
        <MainBtn onClick={onClick} className={styles.frensInviteBtn}>
            <span>{t('invite_friend')}</span>
            <ImageWebp
                src={inviteIconImg}
                srcSet={inviteIconWebpImg}
                alt={"invite"}
                className={styles.frensInviteBtn__icon}
            />
        </MainBtn>
    );
}

export default FrensInviteBtn;
