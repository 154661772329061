import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from "../../../hooks/redux";
import EarnListItem from "./EarnListItem/EarnListItem";
import EarnFormModal from "../EarnFormModal/EarnFormModal";
import { capitalizeText } from "../../../helpers/capitilizeText";
import styles from "./EarnList.module.scss";
import { ITask, Tasks } from '../../../models/ITask';

function EarnList() {
    const tasks: Tasks = useAppSelector(state => state.tasks.tasks);
    const updateLoading = useAppSelector(state => state.tasks.updateLoading);

    const [updatingTask, setUpdatingTask] = useState<number | null>(null);
    const [formModalOpened, setFormModalOpened] = useState(false);
    const [completedTasks, setCompletedTasks] = useState<ITask[]>([]);
    const [uncompletedTasks, setUncompletedTasks] = useState<Tasks>({});
    
    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

    // Фильтрация задач по категориям
    useEffect(() => {
        const filterUncompletedTasks = async () => {
            const filteredTasks: Tasks = Object.keys(tasks).reduce((acc: Tasks, taskCategory: string) => {
                const uncompleted = tasks[taskCategory].filter(task => !task.completed);
                if (uncompleted.length > 0) {
                    acc[taskCategory] = uncompleted;
                }
                return acc;
            }, {} as Tasks);

            const hasUncompletedTasks = Object.keys(uncompletedTasks).some(category => uncompletedTasks[category].length > 0);

            // Если были незавершённые задачи, делаем задержку
            if (hasUncompletedTasks) {
                await delay(5000);
            }

            setUncompletedTasks(filteredTasks);
        };

        filterUncompletedTasks();
    }, [tasks]);

    useEffect(() => {
        if (!updateLoading && updatingTask) {
            setUpdatingTask(null);
        }
    }, [updateLoading, updatingTask]);

    useEffect(() => {
        // Обновляем completedTasks только когда tasks изменяется
        const newCompletedTasks: ITask[] = Object.keys(tasks)
            .flatMap(key => tasks[key])
            .filter(task => task.completed);
        setCompletedTasks(newCompletedTasks);
    }, [tasks]);

    const osSetUpdatingTask = (taskId: number, isForm?: boolean) => {
        setUpdatingTask(taskId);
        if (isForm) setFormModalOpened(true);
    };

    const onCloseModal = (isFullClose?: boolean) => {
        setFormModalOpened(false);
        if (isFullClose) setUpdatingTask(null);
    };

    const setUpdatingTaskMemoized = useCallback(osSetUpdatingTask, []);

    return (
        <>
            <div className={styles.earnList}>
                {
                    Object.keys(uncompletedTasks).map(category => (
                        <div className={styles.earnList__wrapper} key={category}>
                            <h5 className={styles.earnList__typeText}>{capitalizeText(category)}</h5>
                            {
                                uncompletedTasks[category].map(listItem => (
                                    <EarnListItem
                                        key={listItem.id}
                                        item={listItem}
                                        updatingTask={updatingTask}
                                        setUpdatingTask={setUpdatingTaskMemoized}
                                    />
                                ))
                            }
                        </div>
                    ))
                }
                {
                    completedTasks.length > 0 && (
                        <div className={styles.earnList__wrapper} key="completed">
                            <h5 className={styles.earnList__typeText}>Completed</h5>
                            {
                                completedTasks.map(listItem => (
                                    <EarnListItem
                                        key={listItem.id}
                                        item={listItem}
                                        updatingTask={updatingTask}
                                        setUpdatingTask={setUpdatingTaskMemoized}
                                    />
                                ))
                            }
                        </div>
                    )
                }

                <EarnFormModal
                    open={formModalOpened}
                    taskId={updatingTask}
                    onClose={onCloseModal}
                />
            </div>
        </>
    );
}

export default EarnList;
