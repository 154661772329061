import React from 'react';

import HomeWrapper from "../components/HomePage/HomeWrapper/HomeWrapper";


function HomePage() {
    return (
        <HomeWrapper/>
    );
}

export default HomePage;