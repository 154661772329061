import React, {FC} from 'react';
import {useAppSelector} from "../../../hooks/redux";

import FrenItem from "../../FrenItem/FrenItem";
import TransitionProvider, {TransitionStyleTypes} from "../../../providers/TransitionProvider";

import {LeagueTabTypes} from "../../../constants/LeagueTabTypes";
import {leagues} from "../../../constants/Leagues";
import {IFren} from "../../../models/IFren";
import styles from "./LeagueList.module.scss"

interface LeagueListProps {
    setActiveTab: (val: LeagueTabTypes) => void
    activeTab: LeagueTabTypes,
    slideIndex: number
}

const LeagueList: FC<LeagueListProps> = ({setActiveTab, activeTab, slideIndex}) => {
    const data = useAppSelector(state => state.leagues.leagues)

    // get user
    const userId = useAppSelector(state => state.profile.user.userId)
    const pixelLeague = useAppSelector(state => state.profile.user.pixelLeague)
    const coinLeague = useAppSelector(state => state.profile.user.coinLeague)
    const pixelLeaguePosition = useAppSelector(state => state.profile.user.pixelLeaguePosition)
    const firstName = useAppSelector(state => state.profile.user.firstName)
    const lastName = useAppSelector(state => state.profile.user.lastName)
    const username = useAppSelector(state => state.profile.user.username)
    const coins = useAppSelector(state => state.profile.user.coins)
    const pixels = useAppSelector(state => state.profile.user.pixels)
    const coinLeaguePosition = useAppSelector(state => state.profile.user.coinLeaguePosition)

    const curLeague = leagues[slideIndex]

    const curData = data[curLeague.key][activeTab]

    const curUser: IFren = {
        user_id: userId || 0,
        full_name: `${firstName} ${lastName}`,
        username,
        coins,
        pixels,
        active: false,
        referred_by_id: userId || 0,
        reward_for_referrer: 0
    }

    const userStatus = {
        pixelsLeague: pixelLeague,
        coinsLeague: coinLeague,
        pixelsLeaguePosition: pixelLeaguePosition,
        coinsLeaguePosition: coinLeaguePosition,
    }
    return (
        <>
            <div className={styles.leagueList}>
                <div className={styles.leagueList__tabBar}>
                    <button onClick={() => setActiveTab("pixels")} className={styles.leagueList__tab}>PIXELS</button>
                    <button onClick={() => setActiveTab("coins")} className={styles.leagueList__tab}>COINS</button>
                    <div
                        className={`${styles.leagueList__tabActiveBg} ${styles[`leagueList__tabActiveBg_${activeTab}`]}`}></div>
                </div>
                <div className={styles.leagueList__main}>
                    {
                        curData.map((item,index) => (
                            <FrenItem
                                key={item.user_id}
                                item={item}
                                showPixels={activeTab === "pixels"}
                                className={item.user_id === userId ? styles.leagueList__item_active : ""}
                            >
                                <h5 className={styles.leagueList__itemtopText}>{index + 1}</h5>
                            </FrenItem>
                        ))
                    }
                    <TransitionProvider
                        type={TransitionStyleTypes.translateY}
                        inProp={userStatus[`${activeTab}League`] === curLeague.key}
                        className={styles.leagueList__fixedItemWrapper}
                    >
                        <FrenItem
                            item={curUser}
                            className={`${styles.leagueList__item_active}`}
                            showPixels={activeTab === "pixels"}
                        >
                            <h5 className={styles.leagueList__itemtopText}>{userStatus[`${activeTab}LeaguePosition`]}</h5>
                        </FrenItem>
                    </TransitionProvider>
                </div>
            </div>
        </>
    );
}

export default LeagueList;