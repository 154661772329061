import {createSlice} from "@reduxjs/toolkit"

import {IFren} from "../../models/IFren";


export interface FrensState {
    frens: IFren[],
    frensCount: number,
    coins: number,
}


const initialState: FrensState = {
    frens: [],
    frensCount: 0,
    coins: 0,
}

export const tasksSlice = createSlice({
    name: 'tasksSlice',
    initialState,
    reducers: {
        setFrens(state, {payload}) {
            state.frens = payload.frens
            state.frensCount = payload.frensCount
            state.coins = payload.coins
        }
    },
})

export const {
    setFrens
} = tasksSlice.actions;


export default tasksSlice.reducer;
