import React, {FC, ReactNode} from 'react';
import {AppRoot, Modal} from "@xelene/tgui";
import styles from "./CustomModal.module.scss";


interface CustomModalProps {
    open: boolean,
    onClose: () => void,
    children: ReactNode,
    containerClass?: string,
    hideOverlay?: boolean,
}

const CustomModal: FC<CustomModalProps> = ({
                                               open,
                                               onClose,
                                               children,
                                               hideOverlay,
                                               containerClass
                                           }) => {
    return (
        <AppRoot>
            <Modal
                overlayComponent={hideOverlay ? <></> : undefined}
                onOpenChange={(open) => {
                    if (!open) onClose()
                }}
                header={<Modal.Header className={styles.customModal__header}/>}
                open={open}
                className={styles.customModal}
            >
                <div className={`${styles.customModal__container} ${containerClass || ""}`}>
                    {children}
                </div>
            </Modal>
        </AppRoot>);
}

export default CustomModal;