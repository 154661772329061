import React from 'react';
import {useAppSelector} from "../../../hooks/redux";

import FrenItem from "../../FrenItem/FrenItem";

import styles from "./FrensList.module.scss"


function FrensList() {
    const frens = useAppSelector(state => state.frens.frens)

    return (
        <div className={styles.frensList}>
            <div className={styles.frensList__wrapper}>
                {
                    frens.map((item) => (
                        <FrenItem
                            item={item}
                        >
                            <p className={styles.frensList__inviteCoinsTxt}> +{item.reward_for_referrer}</p>
                        </FrenItem>
                    ))
                }
            </div>

        </div>
    );
}

export default FrensList;