import React, {FC} from 'react';

import HomeInfo from "../HomeInfo/HomeInfo";
import HomeRocket from "../HomeRocket/HomeRocket";
import HomeEnergy from "../HomeEnergy/HomeEnergy";

import styles from "./HomeWrapper.module.scss"


const HomeWrapper: FC = () => {
    return (
        <div className={styles.homeWrapper}>
            <HomeInfo/>
            <HomeRocket/>
            <HomeEnergy/>
        </div>
    );
}

export default HomeWrapper;