import * as React from 'react';
import TransitionProvider, {TransitionStyleTypes} from "../../providers/TransitionProvider";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";

import styles from "./DailyCheckin.module.scss"
import ImageWebp from "../layout/ImageWebp/ImageWebp";
import { earnCoinImg, earnCoinWebpImg} from "../../assets/images";
import MainBtn from "../layout/MainBtn/MainBtn";
import {useEffect, useRef, useState} from "react";
import {getLSItem, setLSItem} from "../../helpers/localStorage";
import {lsProps} from "../../utils/lsProps";
import {checkDailyCheckin, hideDailyCheckin, showDailyCheckin} from "../../store/slices/profileSlice";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useTranslation } from 'react-i18next';


type Props = {
    imageLoading: boolean
};
const DailyCheckin: React.FC<Props> = ({imageLoading}) => {
    const dispatch = useAppDispatch()
    const getLoading = useAppSelector(state => state.profile.getUserLoading)
    const onboardingCompleted = useAppSelector(state => state.profile.user.onboardingCompleted)
    const checkinSteak = useAppSelector(state => state.profile.user.checkinSteak)
    const checkinReward = useAppSelector(state => state.profile.user.checkinReward)
    const dailyCheckinDate = useAppSelector(state => state.profile.user.checkinLastDate)
    const dailyCheckinShowing = useAppSelector(state => state.profile.dailyCheckinShowing)
    const hapticFeedback = useHapticFeedback(); 
    const notificationOccurred = hapticFeedback[1];
    const [animating, setAnimating] = useState(false);
    const init = useRef(false);
    const { t } = useTranslation();

    const timeoutRef = useRef<NodeJS.Timer | null>(null)


    useEffect(() => {
        if (!imageLoading && !getLoading && onboardingCompleted) {
            getLSItem(lsProps.dailyCheckinDate, (_, item) => {
                if (item !== dailyCheckinDate) {
                    dispatch(showDailyCheckin());
                    notificationOccurred('success');
                    setAnimating(true);
                    timeoutRef.current = setTimeout(() => {
                        onContinue();
                    }, 5000);
                    setTimeout(() => setAnimating(false), 300);
                } else {
                    dispatch(checkDailyCheckin());
                }
            });
        }
    }, [
        imageLoading,
        getLoading,
        onboardingCompleted,
    ]);
    

    const onContinue = (e?: React.MouseEvent<HTMLButtonElement>) => {
        if(e) e.stopPropagation()
        setLSItem(lsProps.dailyCheckinDate,dailyCheckinDate)
        if(timeoutRef.current) clearTimeout(timeoutRef.current)
        dispatch(hideDailyCheckin())
        dispatch(checkDailyCheckin())
    }


    return (
        <TransitionProvider
         type={TransitionStyleTypes.opacity}
         inProp={dailyCheckinShowing}
         className={styles.dailyCheckin}
        >
            <div className={styles.dailyCheckin__bg}></div>
            <div className={styles.dailyCheckin__main}>
                <ImageWebp
                    src={earnCoinImg}
                    srcSet={earnCoinWebpImg}
                    alt="coin"
                    className={`${styles.dailyCheckin__coinImg} ${animating ? styles.dailyCheckin__coinImg_animating : ""}`}
                    />
                <h1 className={styles.dailyCheckin__daysTxt}>{checkinSteak}</h1>
                <h4 className={styles.dailyCheckin__coinsTxt}>
                    {t('dailyCheckin.daysCheckIn')} <br />
                    <span className="brightText">+{checkinReward} $ROCKET</span>
                </h4>
            </div>
            <div className={styles.dailyCheckin__footer}>
                <h6 className={styles.dailyCheckin__subTxt}>
                    {t('dailyCheckin.excellentVisitDaily')}
                </h6>
                <MainBtn onClick={onContinue}>{t('dailyCheckin.continue')}</MainBtn>
            </div>
        </TransitionProvider>
    );
};

export default DailyCheckin