import React, {useState} from 'react';

import ImageWebp from "../layout/ImageWebp/ImageWebp";

import {qrImg, qrWebpImg} from "../../assets/images";
import styles from "./QRScreen.module.scss"

function QrScreen() {
    const [imgLoaded,setImgLoaded] = useState(false)
    return (
        <>
            <div className={styles.qrScreen}>
                <h3 className={styles.qrScreen__title}>Play on your mobile</h3>
                <div className={styles.qrScreen__main}>
                    <div className={`${styles.qrScreen__imgWrapper} ${!imgLoaded ? styles.qrScreen__imgWrapper_active : ""}`}>
                        <ImageWebp
                            src={qrImg}
                            srcSet={qrWebpImg}
                            alt="qr"
                            onLoad={() => setImgLoaded(true)}
                            className={`${styles.qrScreen__img} ${imgLoaded ? styles.qrScreen__img_active : ""}`}
                        />
                    </div>
                    <h5 className={styles.qrScreen__botName}>@TONMAP</h5>
                </div>
            </div>
        </>
    );
}

export default QrScreen;