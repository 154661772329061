import React from 'react';
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./FrensHeader.module.scss";
import { frensEmojiImg, frensEmojiWebpImg } from "../../../assets/images";
import { useAppSelector } from "../../../hooks/redux";
import { useTranslation } from 'react-i18next';

function FrensHeader() {
    const coins = useAppSelector(state => state.frens.coins);
    const frensCount = useAppSelector(state => state.frens.frensCount);
    const { t } = useTranslation();

    const getFrensLabel = (count: number) => {
        if (count % 10 === 1 && count % 100 !== 11) {
            return t('frensHeader.frens', { count });
        } else if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) {
            return t('frensHeader.frens_1', { count });
        } else {
            return t('frensHeader.frens_2', { count });
        }
    };

    return (
        <div className={styles.frensHeader}>
            <ImageWebp
                src={frensEmojiImg}
                srcSet={frensEmojiWebpImg}
                alt="frens"
                className={styles.frensHeader__img}
            />
            <h2 className={"titleMain"}>{getFrensLabel(frensCount)}</h2>
            <h6 className={styles.frensHeader__subtitle}>{t('frensHeader.title')}</h6>
            <div className={styles.frensHeader__coins}>
                <span className={styles.frensHeader__coinsTxt}>+{coins} Coins</span>
            </div>
        </div>
    );
}

export default FrensHeader;
