import React, {FC} from 'react';

import FrensHeader from "../FrensHeader/FrensHeader";
import FrensInfoBlock from "../FrensInfoBlock/FrensInfoBlock";
import FrensList from "../FrensList/FrensList";
import FrensInviteBtn from "../FrensInviteBtn/FrensInviteBtn";

import styles from "./FrensWrapper.module.scss"


const FrensWrapper:FC = () => {
    return (
        <div className={styles.frensWrapper}>
            <div className={styles.frensWrapper__main}>
                <FrensHeader/>
                <FrensInfoBlock/>
                <FrensList/>
            </div>
            <FrensInviteBtn/>
        </div>
    );
}

export default FrensWrapper;