import React, {FC, HTMLAttributes, ReactNode} from 'react';

import styles from "./MainBtn.module.scss"

interface MainBtnProps  extends HTMLAttributes<HTMLButtonElement> {
    children: ReactNode,
    className?: string
}

const MainBtn:FC<MainBtnProps> = ({children,className,...attrs}) => {
    return (
        <button {...attrs} className={`${styles.mainBtn} ${className || ""}`}>
            {children}
        </button>
    );
}

export default MainBtn;