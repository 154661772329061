import React, {useEffect, useRef, useState} from 'react';
import {useAppSelector} from "../../../hooks/redux";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

import ImageWebp from "../../layout/ImageWebp/ImageWebp";

import {earnCoinImg, earnCoinWebpImg} from "../../../assets/images";
import styles from "./EarnHeader.module.scss"
import { useTranslation } from 'react-i18next';

function EarnHeader() {
    const updateLoading = useAppSelector(state => state.tasks.updateLoading)
    const hapticFeedback = useHapticFeedback();
    const notificationOccurred = hapticFeedback[1]
    const [animating,setAnimating] = useState(false)
    const init = useRef(false)
    const { t } = useTranslation();


    useEffect(() => {
        if(!init.current || updateLoading) {
            init.current = true
            return;
        }

        notificationOccurred('success')
        setAnimating(true)
        setTimeout(() => setAnimating(false),300)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateLoading]);


   

    return (
            <div className={styles.earnHeader}>
                <ImageWebp
                    src={earnCoinImg}
                    srcSet={earnCoinWebpImg}
                    alt="Coin"
                    className={`${styles.earnHeader__img} ${animating ? styles.earnHeader__img_animating : ""}`}
                />
                <h2 className={`titleMain`}>{t('earnHeader.title')}</h2>
            </div>
    );
}

export default EarnHeader;