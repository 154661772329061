import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

import {fetchRequest, getLeaguesUrl} from "../tools/fetchTools";
import {leagues, Leagues} from "../../constants/Leagues";
import {IFren} from "../../models/IFren";
import {GetLeaguesResponse} from "../../models/api/leagues/getLeagues";


export interface LeaguesState {
    leagues: {
        [key in Leagues]: {
            coins: IFren[],
            pixels: IFren[],
        }
    },
    usersCount: number
}

const initialUsers = leagues.reduce((acc: Partial<LeaguesState["leagues"]>, cur) => {
    acc[cur.key] = {
        coins: [],
        pixels: [],
    }
    return acc
}, {}) as LeaguesState["leagues"]

const initialState: LeaguesState = {
    leagues: initialUsers,
    usersCount: 0
}


export const getLeagues = createAsyncThunk<LeaguesState["leagues"], void, { rejectValue: string }>(
    'leagues/getLeagues',
    async (_, {rejectWithValue}) => {


        try {
            const resData = await fetchRequest<GetLeaguesResponse>(getLeaguesUrl)

            const result: Partial<LeaguesState["leagues"]> = {}

            for (let key in resData) {
                result[key as Leagues] = {
                    coins: resData[key as Leagues].top_coins,
                    pixels: resData[key as Leagues].top_pixels,
                }
            }

            return result as LeaguesState["leagues"]

        } catch (error) {
            return rejectWithValue("Error")
        }
    }
)


export const leaguesSlice = createSlice({
    name: 'leaguesSlice',
    initialState,
    reducers: {
        setUsersCount (state,{payload}) {
            state.usersCount = payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLeagues.fulfilled, (state, {payload}) => {
                    state.leagues = payload
                }
            )
    }
})

export const {
    setUsersCount
} = leaguesSlice.actions;


export default leaguesSlice.reducer;
