import React from 'react';

import styles from "./ErrorScreen.module.scss"
import ImageWebp from "../layout/ImageWebp/ImageWebp";
import {errorIconImg, errorIconWebpImg} from "../../assets/images";
import ErrorFormModal from "./ErrorFormModal/ErrorFormModal";

function ErrorScreen() {
    return (
        <>
            <div className={styles.errorScreen}>
                <h2 className={styles.errorScreen__txt}>Technical Maintenance</h2>
                <ImageWebp
                    src={errorIconImg}
                    srcSet={errorIconWebpImg}
                    alt="error"
                    className={styles.errorScreen__img}
                />
            </div>
            <ErrorFormModal/>
        </>
    );
}

export default ErrorScreen;