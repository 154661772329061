import {
    bronzeLeagueImg,
    bronzeLeagueWebpImg,
    diamondLeagueImg,
    diamondLeagueWebpImg,
    epicLeagueImg,
    epicLeagueWebpImg,
    goldLeagueImg,
    goldLeagueWebpImg, immortalLeagueImg, immortalLeagueWebpImg,
    legendaryLeagueImg, legendaryLeagueWebpImg,
    mysticLeagueImg,
    mysticLeagueWebpImg,
    platinumLeagueImg,
    platinumLeagueWebpImg,
    silverLeagueImg,
    silverLeagueWebpImg, worldClassLeagueImg, worldClassLeagueWebpImg
} from "../assets/images";

export enum Leagues {
    bronze = "bronze",
    silver = "silver",
    gold = "gold",
    platinum = "platinum",
    diamond = "diamond",
    epic = "epic",
    mythic = "mythic",
    legendary = "legendary",
    immortal = "immortal",
    world_class = "world_class",
}


export const leagues: {
    name: string
    key: Leagues
    pixels: [number, number]
    coins: [number, number]
    imgSrc: string
    imgWebpSrc: string
}[] = [
    {
        name: "Bronze",
        key: Leagues.bronze,
        pixels: [0, 5],
        coins: [0, 10000],
        imgSrc: bronzeLeagueImg,
        imgWebpSrc: bronzeLeagueWebpImg
    },
    {
        name: "Silver",
        key: Leagues.silver,
        pixels: [6, 10],
        coins: [10001, 50000],
        imgSrc: silverLeagueImg,
        imgWebpSrc: silverLeagueWebpImg
    },
    {
        name: "Gold",
        key: Leagues.gold,
        pixels: [11, 20],
        coins: [50001, 100000],
        imgSrc: goldLeagueImg,
        imgWebpSrc: goldLeagueWebpImg
    },
    {
        name: "Platinum",
        key: Leagues.platinum,
        pixels: [21, 30],
        coins: [100001, 1000000],
        imgSrc: platinumLeagueImg,
        imgWebpSrc: platinumLeagueWebpImg
    },
    {
        name: "Diamond",
        key: Leagues.diamond,
        pixels: [31, 40],
        coins: [1000001, 2000000],
        imgSrc: diamondLeagueImg,
        imgWebpSrc: diamondLeagueWebpImg
    },
    {
        name: "Epic",
        key: Leagues.epic,
        pixels: [41, 100],
        coins: [2000001, 10000000],
        imgSrc: epicLeagueImg,
        imgWebpSrc: epicLeagueWebpImg
    },
    {
        name: "Mythic",
        key: Leagues.mythic,
        pixels: [101, 200],
        coins: [10000001, 50000000],
        imgSrc: mysticLeagueImg,
        imgWebpSrc: mysticLeagueWebpImg
    },
    {
        name: "Legendary",
        key: Leagues.legendary,
        pixels: [201, 500],
        coins: [50000001, 100000000],
        imgSrc: legendaryLeagueImg,
        imgWebpSrc: legendaryLeagueWebpImg
    },
    {
        name: "Immortal",
        key: Leagues.immortal,
        pixels: [501, 1000],
        coins: [100000001, 1000000000],
        imgSrc: immortalLeagueImg,
        imgWebpSrc: immortalLeagueWebpImg
    },
    {
        name: "World Class",
        key: Leagues.world_class,
        pixels: [1001, -1],
        coins: [1000000001, -1],
        imgSrc: worldClassLeagueImg,
        imgWebpSrc: worldClassLeagueWebpImg
    },

]