import React, {Dispatch, FC, MouseEventHandler, SetStateAction, useEffect, useRef} from 'react';
import {useAppSelector} from "../../../hooks/redux";

import Slider from "react-slick";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import ProgressBar from "../../layout/ProgressBar/ProgressBar";

import TransitionProvider, {TransitionStyleTypes} from "../../../providers/TransitionProvider";
import {
    arrowIconImg,
    arrowIconWebpImg,
    leagueAvatar1Img,
    leagueAvatar1WebpImg,
    leagueAvatar2Img,
    leagueAvatar2WebpImg,
    leagueAvatar3Img,
    leagueAvatar3WebpImg,
} from "../../../assets/images";
import {formatNumberShort} from "../../../helpers/formatNumberShort";
import {LeagueTabTypes} from "../../../constants/LeagueTabTypes";
import {leagues} from "../../../constants/Leagues";
import styles from "./LeagueHeader.module.scss"
import {formatNumber} from "../../../helpers/formatNumber";


const SliderArrow: FC<{ position: "right" | "left", onClick?: MouseEventHandler<HTMLButtonElement> }> = ({
                                                                                                             position,
                                                                                                             onClick
                                                                                                         }) => (
    <button
        className={`${styles.leagueHeader__sliderArrow} ${styles[`leagueHeader__sliderArrow_${position}`]}`}
        onClick={onClick}
    >
        <ImageWebp
            srcSet={arrowIconImg}
            src={arrowIconWebpImg}
            alt={"arrow icon"}
            className={styles.leagueHeader__sliderArrowImg}
        />
    </button>
)


const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 1000,
    arrows: true,
    touchmove: false,
    swipe: false,
    infinite: false,
    nextArrow: <SliderArrow position={"right"}/>,
    prevArrow: <SliderArrow position={"left"}/>,
};


const miners = [
    {
        avatar: leagueAvatar1Img,
        avatarWebp: leagueAvatar1WebpImg
    },
    {
        avatar: leagueAvatar2Img,
        avatarWebp: leagueAvatar2WebpImg
    },
    {
        avatar: leagueAvatar3Img,
        avatarWebp: leagueAvatar3WebpImg
    },
]

interface LeagueHeaderProps {
    activeTab: LeagueTabTypes,
    slideIndex: number
    setSlideIndex: Dispatch<SetStateAction<number>>
}

const LeagueHeader: FC<LeagueHeaderProps> = ({activeTab, slideIndex, setSlideIndex}) => {
    const coins = useAppSelector(state => state.profile.user.coins);
    const pixels = useAppSelector(state => state.profile.user.pixels);
    const pixelsLeague = useAppSelector(state => state.profile.user.pixelLeague);
    const coinsLeague = useAppSelector(state => state.profile.user.coinLeague);
    const usersCount = useAppSelector(state => state.leagues.usersCount);
    const touchStart = useRef(0);
    const touchEnd = useRef(0);
    const sliderRef = useRef<Slider | null>(null);

    const userStatus = {
        coins,
        pixels,
        pixelsLeague,
        coinsLeague,
    };

    const curLeague = leagues[slideIndex];

    const isUserInThisLeague = curLeague.key === userStatus[`${activeTab}League`];


    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStart.current = e.touches[0].clientX
    }

    const onTouchMove = (e: TouchEvent) => {
        if (touchStart.current) e.preventDefault()
    }

    const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        touchEnd.current = e.changedTouches[0].clientX
        if (touchStart.current - touchEnd.current > 50 && slideIndex < leagues.length - 1) {
            sliderRef.current?.slickNext()
        } else if (touchStart.current - touchEnd.current < -50 && slideIndex > 0) {
            sliderRef.current?.slickPrev()
        }
        touchStart.current = 0
    }
    useEffect(() => {
        document.body.addEventListener("touchmove", onTouchMove, {passive: false})

        return () => {
            document.body.removeEventListener("touchmove", onTouchMove)
        }
    }, []);
    return (
        <>
            <div className={styles.leagueHeader}>
                <div className={styles.leagueHeader__minersBlock}>
                    <div className={styles.leagueHeader__minersAvatars}>
                        {
                            miners.map(({avatar, avatarWebp}) => (
                                <ImageWebp
                                    key={avatar}
                                    src={avatar}
                                    srcSet={avatarWebp}
                                    alt="avatar"
                                    className={styles.leagueHeader__avaImg}
                                />
                            ))
                        }
                    </div>
                    <h5 className={styles.leagueHeader__minersText}>{formatNumber(usersCount)} miners</h5>
                </div>
                <Slider
                    {...sliderSettings}
                    ref={sliderRef}
                    className={styles.leagueHeader__slider}
                    initialSlide={slideIndex}
                    afterChange={(current) => setSlideIndex(current)}

                >
                    {
                        leagues.map(({
                                         name,
                                         key,
                                         imgSrc,
                                         imgWebpSrc,
                                     }) => (
                            <div
                                onTouchStart={onTouchStart}
                                onTouchEnd={onTouchEnd}
                                className={styles.leagueHeader__sliderItem}
                                key={key}
                            >
                                <div className={styles.leagueHeader__sliderImgWrapper}>
                                    <ImageWebp
                                        src={imgSrc}
                                        srcSet={imgWebpSrc}
                                        alt={name}
                                        className={styles.leagueHeader__sliderImg}/>
                                </div>
                            </div>
                        ))
                    }
                </Slider>
                <h3 className={styles.leagueHeader__nameTxt}>{curLeague.name}</h3>

                <p className={styles.leagueHeader__coinsTxt}>
                    {formatNumberShort(isUserInThisLeague ? userStatus[activeTab] : curLeague[activeTab][0])}
                    {
                        curLeague[activeTab][1] === -1
                            ? "+"
                            : `/${formatNumberShort(curLeague[activeTab][1])}`
                    }
                </p>
                <TransitionProvider
                    type={TransitionStyleTypes.height}
                    inProp={isUserInThisLeague}
                    className={styles.leagueHeader__progressBarWrapper}
                >
                    <ProgressBar
                        value={userStatus[activeTab]}
                        fullValue={curLeague[activeTab][1]}
                    />
                </TransitionProvider>
            </div>
        </>
    );
}

export default LeagueHeader;