import React, { FC } from 'react';
import { useAppDispatch } from "../../../../hooks/redux";
import { updateTaskStatusWithTimeOut } from "../../../../store/slices/tasksSlice";
import ImageWebp from "../../../layout/ImageWebp/ImageWebp";
import {
    arrowIconImg,
    checkIconImg,
    checkIconWebpImg,
    coinImg,
    coinWebpImg
} from "../../../../assets/images";
import { formatNumber } from "../../../../helpers/formatNumber";
import { ITask } from "../../../../models/ITask";
import styles from "./EarnListItem.module.scss";
import { useTelegram } from '../../../../hooks/useTelegram';

interface EarnListItemProps {
    item: ITask,
    setUpdatingTask: (taskId: number, isForm?: boolean) => void,
    updatingTask: null | number
}

const EarnListItem: FC<EarnListItemProps> = ({ item, setUpdatingTask, updatingTask }) => {
    const dispatch = useAppDispatch();
    const tg = useTelegram();

    const {
        id,
        image_name,
        name,
        completed,
        reward,
        link,
        task_type
    } = item;

    const onClickItem = () => {
        if ((task_type === "link" || task_type === "tg") && link) {
            tg && (task_type === "link" ? tg.openLink(link) : tg.openTelegramLink(link));
            dispatch(updateTaskStatusWithTimeOut({ task_id: id }, reward));
            setUpdatingTask(id);
        } else if (task_type === "form") {
            setUpdatingTask(id, true);
        } else if (task_type === "ads_form") {
            setUpdatingTask(id, true);
        } else {
            dispatch(updateTaskStatusWithTimeOut({ task_id: id }, reward));
            setUpdatingTask(id);
        }
    }

    const disabledStyleState = (task_type !== "form" && task_type !== "ads_form") && completed && updatingTask !== id;

    // Динамический импорт изображений
    const getImagePath = (imageName: string, ext: string) => {
        try {
            return require(`../../../../assets/images/tasks/${imageName}.${ext}`);
        } catch (error) {
            console.error(`Image not found: ${imageName}.${ext}`);
            return null; // Или верните путь к изображению-заглушке
        }
    }

    return (
        <button
            disabled={(task_type !== "form" && task_type !== "ads_form") && (completed || !!(updatingTask))}
            className={`${styles.earnListItem} ${disabledStyleState ? styles.earnListItem_disabled : ""}`}
            onClick={onClickItem}
        >
            <div className={styles.earnListItem__main}>
                <ImageWebp
                    src={getImagePath(image_name, 'png')} // Полный путь к PNG картинке
                    srcSet={getImagePath(image_name, 'webp')} // Полный путь к WebP картинке
                    alt={"icon"}
                    className={styles.earnListItem__icon}
                />
                <div className={styles.earnListItem__texts}>
                    <h4 className={styles.earnListItem__nameTxt}>{name}</h4>
                    <div className={styles.earnListItem__coins}>
                        <ImageWebp
                            srcSet={coinWebpImg}
                            src={coinImg}
                            alt={"coin"}
                            className={styles.earnListItem__coinIconimg}
                        />
                        <h6 className={styles.earnListItem__coinTxt}>+{formatNumber(reward)}</h6>
                    </div>
                </div>
            </div>
            {
                updatingTask && updatingTask === id
                    ? <div className={styles.earnListItem__loader}></div>
                    : <ImageWebp
                        src={completed ? checkIconImg : arrowIconImg}
                        srcSet={completed ? checkIconWebpImg : arrowIconImg}
                        alt={"arrow"}
                        className={styles.earnListItem__statusIcon}
                    />
            }
        </button>
    );
}

export default EarnListItem;
