import React, {FC, useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import MainBtn from "../layout/MainBtn/MainBtn";
import { useTranslation } from 'react-i18next';

import styles from "./TutorialSlider.module.scss";
import TransitionProvider, {TransitionStyleTypes} from "../../providers/TransitionProvider";
import {leagues} from "../../constants/Leagues";
import {completeOnboarding} from "../../store/slices/profileSlice";
import {
    onboardingSlide1Img,
    onboardingSlide1WebpImg,
    onboardingSlide2Img,
    onboardingSlide2WebpImg,
    onboardingSlide3Img,
    onboardingSlide3WebpImg,
    onboardingSlide4Img,
    onboardingSlide4WebpImg,
    onboardingSlide5Img,
    onboardingSlide5WebpImg
} from "../../assets/images";
import ImageWebp from "../layout/ImageWebp/ImageWebp";

interface TutorialSliderProps {
    imagesLoading: boolean
}


const sliderSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    touchThreshold: 1000,
    dots: true,
    touchmove: false,
    swipe: false,
    infinite: false,

};

const SliderDot: FC<{ index: number, activeSlideIndex: number }> = ({index, activeSlideIndex}) => (
    <div className={`${styles.sliderDot} ${index === activeSlideIndex ? styles.sliderDot_active : ""}`}>
        <div
            className={`${styles.sliderDot__inner} ${index === activeSlideIndex ? styles.sliderDot__inner_active : ""}`}></div>
    </div>
)


const TutorialSlider: FC<TutorialSliderProps> = ({imagesLoading}) => {
    const dispatch = useAppDispatch()
    const getLoading = useAppSelector(state => state.profile.getUserLoading)
    const onboardingCompleted = useAppSelector(state => state.profile.user.onboardingCompleted)
    const [activeSlideIndex, setActiveSlideIndex] = useState(0)

    const sliderRef = useRef<Slider | null>(null)
    const touchStart = useRef(0)
    const touchEnd = useRef(0)
    const { t } = useTranslation();

    const slides = [
        {
            text: <div dangerouslySetInnerHTML={{ __html: t('slides.slide1.text') }} />,
            image: onboardingSlide1Img,
            imageWebp: onboardingSlide1WebpImg
        },
        {
            text: <div dangerouslySetInnerHTML={{ __html: t('slides.slide2.text') }} />,
            image: onboardingSlide2Img,
            imageWebp: onboardingSlide2WebpImg
        },
        {
            text: <div dangerouslySetInnerHTML={{ __html: t('slides.slide3.text') }} />,
            image: onboardingSlide3Img,
            imageWebp: onboardingSlide3WebpImg
        },
        {
            text: <div dangerouslySetInnerHTML={{ __html: t('slides.slide4.text') }} />,
            image: onboardingSlide4Img,
            imageWebp: onboardingSlide4WebpImg
        },
        {
            text: <div dangerouslySetInnerHTML={{ __html: t('slides.slide5.text') }} />,
            subtext: <div dangerouslySetInnerHTML={{ __html: t('slides.slide5.subtext') }} />,
            image: onboardingSlide5Img,
            imageWebp: onboardingSlide5WebpImg
        },
    ];

    const isLastSlide = activeSlideIndex === slides.length - 1

    const onTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        touchStart.current = e.touches[0].clientX
    }

    const onTouchMove = (e: TouchEvent) => {
        if (touchStart.current) e.preventDefault()
    }

    const onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
        touchEnd.current = e.changedTouches[0].clientX
        if (touchStart.current - touchEnd.current > 50 && activeSlideIndex < leagues.length - 1) {
            sliderRef.current?.slickNext()
        }
        touchStart.current = 0
    }
    useEffect(() => {
        document.body.addEventListener("touchmove", onTouchMove, {passive: false})

        return () => {
            document.body.removeEventListener("touchmove", onTouchMove)
        }
    }, []);

    const onClickNext = () => {
        if (isLastSlide) {
            dispatch(completeOnboarding())
        } else {
            sliderRef.current?.slickNext()
        }
    }

    return (
        <>
            <TransitionProvider
                type={TransitionStyleTypes.opacityLeave}
                onTouchStart={onTouchStart}
                onTouchEnd={onTouchEnd}
                inProp={!getLoading && !imagesLoading && !onboardingCompleted}
                className={styles.tutorialSlider}
            >
                <div className={styles.tutorialSlider__bg}></div>
                <Slider
                    {...sliderSettings}
                    ref={sliderRef}
                    afterChange={setActiveSlideIndex}
                    className={styles.tutorialSlider__slider}
                    customPaging={(i: number) => (
                        <SliderDot activeSlideIndex={activeSlideIndex} index={i}/>
                    )}
                >
                    {
                        slides.map((({imageWebp, image, text, subtext}, index) => (
                            <div className={styles.tutorialSlider__slide} key={index}>
                                <div>
                                    <h3 className={styles.tutorialSlider__slideText}>{text}</h3>
                                    <h6 className={styles.tutorialSlider__slideSubText}>{subtext}</h6>
                                </div>
                                <ImageWebp
                                    className={styles.tutorialSlider__slideImg}
                                    srcSet={imageWebp}
                                    src={image}
                                    alt={"slide"}
                                />
                            </div>
                        )))
                    }
                </Slider>
                <div className={styles.tutorialSlider__footer}>

                        <button
                            onClick={() => dispatch(completeOnboarding())}
                            className={`${styles.tutorialSlider__skipBtn} ${isLastSlide ? styles.tutorialSlider__skipBtn_hidden : ""}`}
                        >{t('buttons.skip')}
                        </button>

                    <MainBtn
                        onClick={onClickNext}
                        className={`${styles.tutorialSlider__finishBtn} ${isLastSlide ? styles.tutorialSlider__finishBtn_finish : ""}`}>
                        {isLastSlide ? t('buttons.play') : t('buttons.next')}
                    </MainBtn>
                </div>
            </TransitionProvider>
        </>

    )
        ;
}

export default TutorialSlider;