import React, {FC, ReactNode} from 'react';

import styles from "./EarnWrapper.module.scss"

interface EarnWrapperProps {
    children: ReactNode
}

const EarnWrapper: FC<EarnWrapperProps> = ({children}) =>  {
    return (
            <div className={styles.earnWrapper}>
                {children}
            </div>
    );
}

export default EarnWrapper;