
export type FetchMethods  =
    "GET" |
    "POST" |
    "PUT" |
    "PATCH" |
    "DELETE"

export const baseUrl = '/api';
export const baseConfig = {
    headers: {
        'Content-Type': 'application/json',
    }
}

// ENDPOINTS

// profile
export const getProfileUrl = '/get_user'
export const calculateCoinsUrl = '/calculate_coins'
export const claimRewardsUrl = '/claim_rewards'
export const onboardingCompleteUrl = '/user/onboarding_complete'

// tasks
export const getTasksUrl = '/user/tasks'
export const updateTaskStatusUrl = '/user/tasks/update_status'

// frens
export const getFrensUrl = '/get_user_referrals'

// leagues
export const getLeaguesUrl = '/top_leagues'

// subscribe
export const getSubscriptionStatusUrl = '/subscribe'

// leagues
export const AdsViewedUrl = '/adsviewed'

export const fetchRequest = async <Res,Body extends object = {}>(
    fetchUrl: string,
    method:FetchMethods = "GET",
    body:Body | null = null,
    config = baseConfig
) => {

    const response = await fetch(`${baseUrl}${fetchUrl}`, {
        method: method,
        body: body && JSON.stringify(body),
        ...config
    });
    const resData:Res = await response.json();
    console.log(resData)
    if (!response.ok) {
        // eslint-disable-next-line no-throw-literal
        throw {message: resData, status: response.status};
    }
    return resData
}
