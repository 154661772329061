import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ShowPromiseResult, AdController } from '../../types/adsgram'; // Импортируйте нужные типы
import styles from "../layout/MainBtn/MainBtn.module.scss";
import { useAppDispatch } from '../../hooks/redux';
import { updateTaskStatusWithTimeOut } from '../../store/slices/tasksSlice';
import { getLSItem, setLSItem } from "../../helpers/localStorage";

export function ShowAdButton(): React.ReactElement {
    const adControllerRef = useRef<AdController | null>(null);
    const dispatch = useAppDispatch();
    const rewardAmount = 100; 
    const taskId = 0; 
    const [adViewCount, setAdViewCount] = useState<number>(0);
    const adViewKey = 'ad_view_count';

    const updateAdViewCount = useCallback((newCount: number) => {
        const today = new Date().toISOString().split('T')[0];
        const viewData = { date: today, count: newCount };
        setLSItem(adViewKey, viewData);
        setAdViewCount(newCount);
    }, [adViewKey]);

    const onReward = useCallback(() => {
        getLSItem(adViewKey, (error, result) => {
            if (error) {
                console.error('Ошибка при получении данных о просмотрах:', error);
                return;
            }

            let viewData = { date: '', count: 0 };
            const today = new Date().toISOString().split('T')[0];

            if (result) {
                viewData = JSON.parse(result);
            }

            if (viewData.date !== today) {
                viewData = { date: today, count: 0 };
            }

            if (viewData.count < 20) {
                viewData.count += 1;
                updateAdViewCount(viewData.count);

                // Ваш код для выдачи награды и обновления статуса задания
                dispatch(updateTaskStatusWithTimeOut({ task_id: taskId }, rewardAmount));
            } else {
                alert('The limit of ad views for today has been reached. Please come back tomorrow!');
            }
        });
    }, [dispatch, adViewKey, taskId, rewardAmount, updateAdViewCount]);

    const onError = useCallback((result: ShowPromiseResult) => {
        alert(`Error: ${JSON.stringify(result, null, 4)}`);
    }, []);

    const showAd = useCallback(() => {
        getLSItem(adViewKey, (error, result) => {
            if (error) {
                console.error('Ошибка при получении данных о просмотрах:', error);
                return;
            }

            let viewData = { date: '', count: 0 };
            const today = new Date().toISOString().split('T')[0];

            if (result) {
                viewData = JSON.parse(result);
            }

            if (viewData.date !== today) {
                viewData = { date: today, count: 0 };
                setLSItem(adViewKey, viewData);
            }

            if (viewData.count >= 20) {
                alert('The limit of ad views for today has been reached. Please come back tomorrow!');
            } else {
                if (adControllerRef.current) {
                    adControllerRef.current.show().catch(onError);
                }
            }
        });
    }, [onError, adViewKey]);

    useEffect(() => {
        if (window.Adsgram) {
            const adController = window.Adsgram.init({
                blockId: "1682",
                debug: false,
                debugBannerType: "FullscreenMedia",
            });

            adController.addEventListener('onReward', onReward);
            adControllerRef.current = adController;

            return () => {
                adController.removeEventListener('onReward', onReward);
                // adController.destroy();
            };
        }
    }, [onReward, onError]);

    useEffect(() => {
        getLSItem(adViewKey, (error, result) => {
            if (error) {
                console.error('Ошибка при получении данных о просмотрах:', error);
                return;
            }

            let viewData = { date: '', count: 0 };
            const today = new Date().toISOString().split('T')[0];

            if (result) {
                viewData = JSON.parse(result);
            }

            if (viewData.date !== today) {
                viewData = { date: today, count: 0 };
                setLSItem(adViewKey, viewData);
            }

            setAdViewCount(viewData.count);
        });
    }, [adViewKey]);

    return (
        <button className={styles.mainBtn} onClick={showAd}>
            Show Ad {adViewCount}/20
        </button>
    );
}

export default ShowAdButton;
