import React from 'react';
import { useTranslation } from 'react-i18next';

import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import styles from "./FrensInfoBlock.module.scss";
import { coinImg, coinWebpImg, telegramPremiumImg, telegramPremiumWebpImg } from "../../../assets/images";

function FrensInfoBlock() {
    const { t } = useTranslation();

    return (
        <div className={styles.frensInfoBlock}>
            <div className={styles.frensInfoBlock__main}>
                <div className={styles.frensInfoBlock__mainItem}>
                    <ImageWebp
                        src={coinImg}
                        srcSet={coinWebpImg}
                        alt="coin"
                        className={styles.frensInfoBlock__iconImg}
                    />
                    <p className={styles.frensInfoBlock__nameTxt}>{t('frensInfoBlock.fren')}</p>
                    <p className={styles.frensInfoBlock__coinsTxt}>+2500</p>
                </div>
                <div className={styles.frensInfoBlock__mainItem}>
                    <ImageWebp
                        src={telegramPremiumImg}
                        srcSet={telegramPremiumWebpImg}
                        alt="telegram premium"
                        className={styles.frensInfoBlock__iconImg}
                    />
                    <p className={styles.frensInfoBlock__nameTxt}>{t('frensInfoBlock.telegramPremium')}</p>
                    <p className={styles.frensInfoBlock__coinsTxt}>+10 000</p>
                </div>
                <div className={styles.frensInfoBlock__mainItem}>
                    <ImageWebp
                        src={coinImg}
                        srcSet={coinWebpImg}
                        alt="coin"
                        className={styles.frensInfoBlock__iconImg}
                    />
                    <p className={styles.frensInfoBlock__nameTxt}>{t('frensInfoBlock.boughtLand')}</p>
                    <p className={styles.frensInfoBlock__coinsTxt}>+5 000</p>
                </div>
            </div>
            <h3 className={styles.frensInfoBlock__title}>{t('frensInfoBlock.title')}</h3>
        </div>
    );
}

export default FrensInfoBlock;
