import React, {useEffect, useState} from 'react';
import {useTelegram} from "../../../hooks/useTelegram";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../../hooks/redux";

import LeagueHeader from "../LeagueHeader/LeagueHeader";
import LeagueList from "../LeagueList/LeagueList";

import {LeagueTabTypes} from "../../../constants/LeagueTabTypes";
import {leagues} from "../../../constants/Leagues";
import styles from "./LeagueWrapper.module.scss"

function LeagueWrapper() {
    const tg = useTelegram()
    const navigate = useNavigate()
    const coinsLeague = useAppSelector(state => state.profile.user.coinLeague)
    const [activeTab,setActiveTab] = useState<LeagueTabTypes>("coins")
    const curLeagueIndex = leagues.findIndex(item => item.key === coinsLeague)
    const [slideIndex, setSlideIndex] = useState(curLeagueIndex)


    useEffect(() => {
        tg?.BackButton.show();
        tg?.BackButton.onClick(() => navigate(-1))
        return () => {
            tg?.BackButton.hide();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.leagueWrapper}>
            <LeagueHeader
                activeTab={activeTab}
                slideIndex={slideIndex}
                setSlideIndex={setSlideIndex}
            />
            <LeagueList
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                slideIndex={slideIndex}
            />
        </div>
    );
}

export default LeagueWrapper;