import React from 'react';
import EarnHeader from "../components/EarnPage/EarnHeader/EarnHeader";
import EarnList from "../components/EarnPage/EarnList/EarnList";
import EarnWrapper from "../components/EarnPage/EarnWrapper/EarnWrapper";

function EarnPage() {
    return (
        <EarnWrapper>
            <EarnHeader/>
            <EarnList/>
        </EarnWrapper>
    );
}

export default EarnPage;