import React, { FC } from 'react';
import CustomModal from "../../layout/CustomModal/CustomModal";
import MainBtn from "../../layout/MainBtn/MainBtn";
import styles from "./EarnFormModal.module.scss";
import { useTelegram } from '../../../hooks/useTelegram';
import { ShowAdButton } from '../showAdButton';
import { useTranslation } from 'react-i18next';


interface EarnFormModalProps {
    open: boolean,
    onClose: (isFullClose?: boolean) => void,
    taskId: number | null
}

const TIKTOK_BOT_URL = "https://t.me/thetonmapbot?start=contenttask";

const EarnFormModal: FC<EarnFormModalProps> = ({ open, taskId, onClose }) => {
    const tg = useTelegram();
    const { t } = useTranslation();


    const onSend = () => {
        if (!taskId) return;
        tg?.openTelegramLink(TIKTOK_BOT_URL);
        tg?.close();
        onClose();
    }

    return (
        <CustomModal
            open={open}
            onClose={() => onClose(true)}
        >
            <div className={styles.earnFormModal}>
                <div className={styles.earnFormModal__textblock}>
                    {
                        taskId === 0 ? (
                            <>
                                <h3 className={styles.earnFormModal__txt}>
                                {t('earnFormModal.specialOffer')}
                                </h3>
                            </>
                        ) : (
                            <>
                                <h3 className={styles.earnFormModal__txt}>
                                {t('earnFormModal.earnCoins')}
                                </h3>
                            </>
                        )
                    }
                </div>
                {
                        taskId === 0 ? (
                           
                                <ShowAdButton />
                        
                        ) : (
                          
                            <MainBtn onClick={onSend}>{t('earnFormModal.ready')}</MainBtn>
            
                        )
                    }
            </div>
        </CustomModal>
    );
}

export default EarnFormModal;
